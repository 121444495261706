export const environment = {
    production: false,
    oktaConfig: {
        baseUrl: 'https://amway.okta.com',
        issuer: 'https://amway.okta.com/oauth2/default',
        redirectUri: 'https://durables-admin-dev.amway.net/main',
        logoutRedirectUri: 'https://durables-admin-dev.amway.net/login',
        clientId: '0oa1h8n81gph8ZBfp0h8'
    },
    componentMinSerialLength:17,
    maxSerialNumberLength: 17,
    minSerialNumberLength: 13,
    minSkuNumberLength: 6,
    AzureFunctionUrl: 'https://durableservices-dev-api-functions.azurewebsites.net',
    apiKey: 'pHekWeKJfagbKnP0FbXs128DpC8xvkhy25XB7dltY5gNSZaayTzAWw=='
    // AzureFunctionUrl: 'http://localhost:7071'
  };
